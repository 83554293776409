<template>
    <button @click="$emit('action')">
        <slot/>
    </button>
</template>

<script>
export default {
    name: "yellow-button",
}
</script>

<style scoped>
button {
    display: block;
    padding: 14px 28px;
    background-color: #ffdd2d;
    transition-duration: .5s;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    border-radius: 10px;
}

button:hover {
    background-color: #fab619;
}

</style>