<template>
    <site-header/>
    <div class="bg-[#f6f7f8]">
        <div class="container">
            <h1 class="w-2/3 sm:w-full mx-auto pt-16 sm:pt-12">Получите кредит на&nbsp;автомобиль без&nbsp;первоначального взноса</h1>
            <div class="hidden sm:block text-center mt-4">На новую машину или с пробегом. Нужен только паспорт РФ</div>

            <div class="grid grid-cols-3 mt-16 gap-8 sm:hidden">
                <div class="rounded-3xl bg-white text-center">
                    <img class="bg-[#dddfe0] rounded-2xl h-12 -mt-6 mx-auto" src="@/assets/images/i1-1.svg" alt="i1-1">
                    <div class="pb-6 mt-5">До 8 000 000 ₽</div>
                </div>
                <div class="rounded-3xl bg-white text-center">
                    <img class="bg-[#dddfe0] rounded-2xl h-12 -mt-6 mx-auto" src="@/assets/images/i1-2.svg" alt="i1-2">
                    <div class="pb-6 mt-5">На новую машину или с пробегом</div>
                </div>
                <div class="rounded-3xl bg-white text-center">
                    <img class="bg-[#dddfe0] rounded-2xl h-12 -mt-6 mx-auto" src="@/assets/images/i1-3.svg" alt="i1-3">
                    <div class="pb-6 mt-5">Срок кредита до 5 лет</div>
                </div>
            </div>
        </div>

        <frame-form class="mt-12 sm:mt-6" url="https://tbank.ru/loans/auto-loan/iframe/form"/>

        <div class="container">
            <div class="hidden sm:block">
                <h2 class="mt-12">Преимущества автокредита в&nbsp;Т-Банке</h2>
                <div class="bg-white rounded-3xl p-6 mt-8">
                    <img src="@/assets/images/i2-1.svg" alt="i2-1">
                    <h3 class="mt-6">До 8 000 000 ₽</h3>
                </div>
                <div class="bg-white rounded-3xl p-6 mt-6">
                    <img src="@/assets/images/i2-2.svg" alt="i2-2">
                    <h3 class="mt-6">До 5 лет — срок погашения</h3>
                </div>
                <div class="bg-white rounded-3xl p-6 mt-6">
                    <img src="@/assets/images/i2-3.svg" alt="i2-3">
                    <h3 class="mt-6">Первоначальный взнос отсутствует</h3>
                </div>
                <div class="bg-white rounded-3xl p-6 mt-6">
                    <img src="@/assets/images/i2-4.svg" alt="i2-4">
                    <h3 class="mt-6">Без оформления Каско и прочих услуг</h3>
                </div>
            </div>
            <h2 class="mt-20">Удобно от заявки до&nbsp;покупки</h2>
            <div class="grid grid-cols-4 mt-16 gap-8 sm:gap-0 sm:grid-cols-1">
                <div class="mt-2">
                    <div class="rounded-3xl bg-white text-center px-6 py-7 z-0 h-full">
                        <div class="bg-[#333333] text-white rounded-2xl h-12 w-12 -mt-12 z-10 mx-auto numbers text-xl flex items-center justify-center">1</div>
                        <h3 class="mt-8">Заявка на&nbsp;кредит</h3>
                        <div class="mt-2">Оставьте заявку онлайн — банк ответит сразу, в день обращения</div>
                    </div>
                </div>
                <div class="mt-2 sm:mt-8">
                    <div class="rounded-3xl bg-white text-center px-6 py-7 z-0 h-full">
                        <div class="bg-[#333333] text-white rounded-2xl h-12 w-12 -mt-12 z-10 mx-auto numbers text-xl flex items-center justify-center">2</div>
                        <h3 class="mt-8">Получение денег</h3>
                        <div class="mt-2">Банк бесплатно доставит деньги на дебетовой карте, куда удобно</div>
                    </div>
                </div>
                <div class="mt-2 sm:mt-8">
                    <div class="rounded-3xl bg-white text-center px-6 py-7 z-0 h-full">
                        <div class="bg-[#333333] text-white rounded-2xl h-12 w-12 -mt-12 z-10 mx-auto numbers text-xl flex items-center justify-center">3</div>
                        <h3 class="mt-8">Покупка машины</h3>
                        <div class="mt-2">Отправьте в банк фото СТС для регистрации залога</div>
                    </div>
                </div>
                <div class="mt-2 sm:mt-8">
                    <div class="rounded-3xl bg-white text-center px-6 py-7 z-0 h-full">
                        <div class="bg-[#333333] text-white rounded-2xl h-12 w-12 -mt-12 z-10 mx-auto numbers text-xl flex items-center justify-center">4</div>
                        <h3 class="mt-8">Другие покупки</h3>
                        <div class="mt-2">Потратьте остаток на то, что нужно вашему новому автомобилю</div>
                    </div>
                </div>
            </div>

            <div class="grid-cols-3 grid mt-12 sm:hidden">
                <img src="@/assets/images/bot.webp" alt="bot" class="col-span-2 bg-[#eaecee] rounded-3xl">
                <div class="pt-8 pl-8">
                    <h3 class="mb-4">Преимущества автокредита в&nbsp;Т-Банке</h3>
                    <custom-list :items="[
                        'Без справок о доходах и поручителей, нужен только паспорт РФ',
                        'Вы покупаете машину сами, без участия банка',
                        'Без оформления Каско и прочих услуг',
                        'Если хотите, проверим автомобиль по открытым базам ГИБДД, ФНП, ФССП и банковским источникам'
                    ]" type="dash"/>
                </div>
            </div>

            <yellow-button class="mt-12 mx-auto" @click="gotoForm">Оформить кредит</yellow-button>
            <div class="mt-36 sm:mt-10">&nbsp;</div>
        </div>
    </div>
    <site-footer :product-links="links" copyright="" disclaimer="" bank-links=""></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header-v2";
import SiteFooter from "@/components/site-footer-v2";
import FrameForm from "@/components/frame-form.vue";
import CustomList from "@/components/custom-list.vue";
import YellowButton from "@/components/yellow-button.vue";

export default {
    name: 'App',
    components: {YellowButton, CustomList, FrameForm, SiteHeader, SiteFooter},

    data: () => ({
        urlParams: '',
        links: [
            {text: 'Тарифы', link: require('@/assets/pdf/Тарифы.pdf')},
            {text: 'Условия комплексного банковского обслуживания', link: require('@/assets/pdf/627ee150-0276-4a81-9d0c-8be1e265ae87.pdf')},
            {text: 'Условия программы страхования', link: require('@/assets/pdf/9fd26052-7200-4593-aa8e-97ad4d5ccd06.pdf')},
            {text: 'Тарифы по автокредиту', link: require('@/assets/pdf/ff704f95-458a-4df2-8587-54139be95e1b.pdf')},
            {text: 'Требования к автомобилю', link: require('@/assets/pdf/loans-car-requirements.pdf')},
            {text: 'Требования к документам', link: require('@/assets/pdf/documents-required-consideration-application-car-loan.pdf')},
            {text: 'Условия договора', link: require('@/assets/pdf/ea4166ab-9a50-4f10-a398-ff9b71c35b48.pdf')},
        ],
    }),

    methods: {
        gotoForm() {
            window.scrollTo({
                top: document.getElementById('form-iframe').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }
    },

    created() {
        this.urlParams = window.location.search;
    },
}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 13px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    line-height: 22px;
    color: #333333;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    @media screen and (max-width: 575px) {
        font-size: 15.5px;
    }
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 600;
    font-variant-ligatures: no-contextual;
    font-size: 36.7px;
    line-height: 40px;
    letter-spacing: 0;
    text-align: center;
    @media screen and (max-width: 575px) {
        font-size: 32px;
        line-height: 36px;
        font-weight: 400;
    }
}

h3 {
    font-size: 24px;
    line-height: 28px;
    @media screen and (max-width: 575px) {
        font-size: 19px;
        line-height: 24px;
    }
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.splide__pagination {
    bottom: -30px;

    li {
        button {
            background: #DDDFE0;

            &.is-active {
                background: #FFDD2D;
            }
        }
    }
}

.splide__arrow {
    background: #eee;

    svg {
        fill: #336fee;
    }

    &:disabled {
        display: none;
    }

    &.splide__arrow--next {
        right: -3em;
    }

    &.splide__arrow--prev {
        left: -3em;
    }
}

.accordion-list {
    width: 100%;

    .accordion-item {
        --content-height: 0px;
        height: calc(var(--summary-height) + 26px);
        overflow: hidden;
        transition: height 300ms ease-in-out;

        > .accordion-item__summary {
            padding: 12px 0;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: color 300ms ease-in-out;

            &::-webkit-details-marker {
                display: none;
            }

            > .accordion-item__summary-icon {
                transition: transform 300ms ease-in-out;
            }

            > .accordion-item__summary-icon--default::before {
                content: "▼";
                opacity: 30%;
                line-height: 22px;
                font-size: 14px;
            }
        }

    }

    .accordion-item--open {
        height: calc(var(--summary-height) + var(--content-height) + 51px);
        background: var(--acco-lightest);

        > .accordion-item__summary > .accordion-item__summary-icon {
            transform: rotate(180deg);
        }

        p {
            margin-bottom: 15px;
            line-height: 25px;
        }

        li {
            margin-bottom: 10px;
            padding-left: 20px;

            &:before {
                content: '— ';
                margin-left: -20px;
                position: relative;
            }
        }
    }
}

</style>
